*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  -moz-text-size-adjust: none;
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
}

/* Set core body defaults */
body {
  min-height: 100vh;
  line-height: 1.5;
}

h1 {
  font-size: 1.5rem;
}

h1, h2, h3, h4,
button, input, label {
  line-height: 1.1;
}

h1, h2,
h3, h4 {
  text-wrap: balance;
}

a:not([class]) {
  text-decoration-skip-ink: auto;
  color: currentColor;
}

a:hover {
  text-decoration: none;
}

img,
picture {
  width: 100%;
  max-width: 100%;
  display: block;
}

input, button,
textarea, select {
  font: inherit;
}

textarea:not([rows]) {
  min-height: 10em;
}

:target {
  scroll-margin-block: 5ex;
}

body {
  height: 100vh;
  background: whitesmoke;
}

.copy {
  cursor: pointer;

  &:hover {
    color: rgba(0,0,0, 0.4);
  }
}

// Long text
@media (max-width: 600px) {
  .address {
    font-size: 0.7rem;
  }
}

// Error message
.blank-error-message {
  font-family: Tahoma, Verdana, Arial, sans-serif;

  h1 {
    font-weight: bold;
  }
}

// Tooltip
#react-tiny-popover-container {
  .popover-arrow-container {
    -webkit-animation: anvil 0.3s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
    animation: anvil 0.3s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
    cursor: pointer;

    .popover-content {
      background: #000;
      color: #fff;
      padding: 0.1rem 0.4rem;
      border-radius: 0.2rem;
      font-size: 0.9rem;
    }
  }
}

// Session page
.cosmo {
    margin-top: 1.5rem;

    .form-control, 
    .btn {
      border-radius: 0.5rem;
    }

    .notify {
      .input-group {
        .form-control {
          background:#edf2f8;
          border-color: transparent;
          box-shadow: none !important;
        }
        .input-group-append .btn-outline-secondary {
          border-color: #edf2f8 !important;
          border-radius: 0 0.5rem 0.5rem 0;
          box-shadow: none !important;

          &:hover {
            background-color: #edf2f8;
            color: rgb(108, 117, 125) !important;
          }
        } 
      }
    }

    .header,
    .content,
    .footer {
      max-width: 600px;
    }

    .header {
      .left {
        a {
          text-decoration: none;
          border-bottom: 1px solid rgba(0, 0, 0, 0.3);

          i {
            position: relative;
            top: 1px;
          }
        }
      }
      .right {
        text-align: right;

        a {
          color: blue;
          text-decoration: none;
          font-weight: 500;
        }
      }
    }

    .content {
        background: #fff;
        border-radius: 1rem;
        padding: 1rem;

        .mark-text {
          text-align: left;
          
          & > div {
            display: inline-block;
            color: #000;
            border-bottom: 3px solid blue;
          }

          .copy {
            cursor: pointer;
            position: relative;
            top: 2px;
            display: inline-block;
            margin: 0 0 0 5px;
          }
        }
        .address {
            .text {
                padding-right: 33px;
                position: relative;

                .ico {
                    position: absolute;
                    right: 5px;
                    top: 0;

                    svg {
                        width: 20px;
                        color: #000;
                    }
                }
            }
        }
    }

    .footer {
      line-height: 1;
      font-size: 0.9rem;
    }

    .result-message {
      .ico {
        font-size: 5rem;
      }
    }
}

.cosmo-popup-overlay {
  overflow: auto;
  
  .cosmo-popup-content {
    border-radius: 1rem;
    padding: 1rem;
    width: 100%;
    max-width: 700px;
  
    .close {
      position: absolute;
      top: 0px;
      font-size: 30px;
      cursor: pointer;
      right: 15px;
    }
    .qr {
      max-width: 250px;
      margin: auto;
    }
  }
}

@media (max-width: 768px) {
  .cosmo .content .id {
    font-size: 1rem;
  }
}

// Usual page
.space {
  background: #fff;
  border-radius: 1rem;
  padding: 1rem 1.5rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

// How to page
.guide-slider {
  padding: 25px 12px 21px 13px ;
  background: url("images/phone-frame-top.png") left 0 no-repeat, url("images/phone-frame-bottom2.png") left bottom no-repeat;
  background-size: contain;
  position: relative;
  max-width: 400px;
  margin: auto;

  .carousel {
      border-radius: 18px;
      overflow: hidden;

      .control-arrow { 
        background: rgba(0,0,0,0.2) !important
      }
  }
}

@media (max-width: 1400px) {
  .guide-slider {
    padding: 21px 10px 18px 10px;
  }
}

@media (max-width: 1200px) {
  .guide-slider {
    padding: 17px 7px 13px 8px;
  }
}

// Front page
.infinity {
  height: 100vh;
  color: #fff;
  background:  #575CE9;
  
  .container {
    height: 100vh;
    background: url("./images/hands.svg") right center no-repeat;
    background-size: contain;
    max-width: 920px;
  }

  table {
    height: 100vh;
  }
  .va {
    vertical-align: middle;
    // background: rgba(0,0,0,0.3);
    padding: 0 1rem;
  }

  h1 {
    font-size: 2.5rem;

    span {
      color: #575CE9;
      background: #fff;
      display: inline-block;
      padding: 5px 10px;
    }
  }

  .slogan {
    font-size: 2.5rem;
    font-weight: 300;
    line-height: 1.2;
  }

  .banner {
    img {
      max-width: 650px;
    }
  }
}

@media (max-width: 600px) {
  .infinity {
    .container {
      background-image: none;
    }
    table {
      width: 100%;
    }
  }
}

// Popup animation
@keyframes anvil {
  0% {
    transform: scale(1) translateY(0px);
    opacity: 0;
    box-shadow: 0 0 0 rgba(241, 241, 241, 0);
  }
  1% {
    transform: scale(0.96) translateY(10px);
    opacity: 0;
    box-shadow: 0 0 0 rgba(241, 241, 241, 0);
  }
  100% {
    transform: scale(1) translateY(0px);
    opacity: 1;
    box-shadow: 0 0 500px rgba(241, 241, 241, 0);
  }
}
.popup-content {
  -webkit-animation: anvil 0.3s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
  animation: anvil 0.3s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
}

// Spinner
.spinner {
  position: relative;
  width: 35px;
  height: 35px;
  margin: auto;

  .spinner-circle {
    height: 100%;
    right: 0px;
    position: absolute;
    border: solid 4px #dedfe0;
    border-top-color:  blue;
    border-radius: 50%;
  }
  
  .spinner-border {
    width: 100%;
    transform: rotate(135deg);  
    animation: spin 1.3s steps(2) .2s infinite;
    -webkit-animation: spin 1.5s linear infinite;
  }
}

@-webkit-keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}